import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'zepto';
import _ from 'lodash';
import createReactClass from 'create-react-class';
import { ensureWithinLimits } from '../math/math';
import { request as requestAnimationFrame, cancel as cancelAnimationFrame, } from '../animationFrame/animationFrame';
import { setOffset } from '../elementUtils/elementUtils';
import PropTypes from 'prop-types';
/**
 * A HOC to allow drag of fixed position components
 * Supports drag limits, and dragging a different element than the root element of the class
 */
const $document = $(window.document);
const getDraggableSlotPosition = (draggable) => ({
    y: draggable.nodePosition.y,
    x: draggable.nodePosition.x,
    width: draggable.nodeSizes.width,
    height: draggable.nodeSizes.height,
});
const draggableHOC = (Comp) => createReactClass({
    displayName: `Draggable(${Comp.displayName || ''})`,
    propTypes: {
        registerDragComponent: PropTypes.func,
        unregisterDragComponent: PropTypes.func,
        updateDragComponentPosition: PropTypes.func,
    },
    getInitialState() {
        this.dragCallbacks = {};
        this.isBeingDragged = false;
        this.draggable = {};
        return {
            isCustomPosition: false,
        };
    },
    componentDidMount() {
        if (this.props.registerDragComponent) {
            const domNOde = $(ReactDOM.findDOMNode(this));
            this.setDraggableNodePosition(domNOde);
            this.setDraggableNodeSize(domNOde);
            this.props.registerDragComponent(getDraggableSlotPosition(this.draggable));
        }
    },
    componentWillUnmount() {
        if (this.draggable.element) {
            this.endDrag();
        }
        if (this.props.unregisterDragComponent) {
            this.props.unregisterDragComponent();
        }
    },
    setDraggableNodePosition(element) {
        const offset = element.offset();
        this.draggable.nodePosition = {
            y: offset.top - window.pageYOffset,
            x: offset.left - window.pageXOffset,
        };
    },
    setDraggableNodeSize(element) {
        this.draggable.nodeSizes = {
            height: element.height(),
            width: element.width(),
        };
    },
    startDrag(event, limits, dragElement) {
        const { draggable } = this;
        draggable.limits = {
            x: [],
            y: [],
            lockedX: false,
            lockedY: false,
        };
        draggable.element = $((_.isObject(dragElement) && ReactDOM.findDOMNode(dragElement)) ||
            ReactDOM.findDOMNode(this));
        if (typeof limits !== 'string') {
            _.assign(draggable.limits, limits);
        }
        this.setDraggableNodePosition(draggable.element);
        draggable.relPosition = {
            x: event.pageX - window.pageXOffset - draggable.nodePosition.x,
            y: event.pageY - window.pageYOffset - draggable.nodePosition.y,
        };
        this.setDraggableNodeSize(draggable.element);
        draggable.event = {};
        $document.on({
            'mousemove.draggable': this.onDrag,
            'mouseup.draggable': this.endDrag,
        });
        $(draggable.element).css({ transition: 'none' });
    },
    drag() {
        this.setState({
            isCustomPosition: true,
        });
        const { draggable } = this;
        draggable.pendingFrameRequest = 0;
        const { limits } = draggable;
        let left = draggable.event.pageX - window.pageXOffset - draggable.relPosition.x;
        left = limits.lockedX
            ? ensureWithinLimits(left, draggable.nodePosition.x, draggable.nodePosition.x)
            : ensureWithinLimits(left, limits.x[0], limits.x[1] - draggable.nodeSizes.width);
        let top = draggable.event.pageY - window.pageYOffset - draggable.relPosition.y;
        top = limits.lockedY
            ? ensureWithinLimits(top, draggable.nodePosition.y, draggable.nodePosition.y)
            : ensureWithinLimits(top, limits.y[0], limits.y[1] - draggable.nodeSizes.height);
        draggable.nodePosition.x = left;
        draggable.nodePosition.y = top;
        if (this.props.updateDragComponentPosition) {
            this.props.updateDragComponentPosition(getDraggableSlotPosition(draggable));
        }
        const draggableOffset = {
            left: draggable.nodePosition.x,
            top: draggable.nodePosition.y,
        };
        if (this.dragCallbacks.whileDrag) {
            this.dragCallbacks.whileDrag(draggableOffset);
        }
        else {
            setOffset(draggable.element, draggableOffset.top, draggableOffset.left);
        }
    },
    onDrag(event) {
        if (!this.isBeingDragged) {
            this.isBeingDragged = true;
            if (this.dragCallbacks.onDragStart) {
                this.dragCallbacks.onDragStart();
            }
        }
        const { draggable } = this;
        draggable.event.pageX = event.pageX;
        draggable.event.pageY = event.pageY;
        if (!draggable.pendingFrameRequest) {
            draggable.pendingFrameRequest = requestAnimationFrame(this.drag);
        }
        return false;
    },
    endDrag() {
        $document.off('.draggable');
        const { draggable } = this;
        cancelAnimationFrame(draggable.pendingFrameRequest);
        draggable.pendingFrameRequest = 0;
        $(draggable.element).css({ transition: '' });
        if (this.isBeingDragged) {
            this.isBeingDragged = false;
            if (this.dragCallbacks.onDragEnd) {
                this.dragCallbacks.onDragEnd({
                    left: draggable.nodePosition.x,
                    top: draggable.nodePosition.y,
                });
            }
        }
        draggable.element = undefined;
    },
    render() {
        const props = _.defaults({
            startDrag: this.startDrag,
            endDrag: this.endDrag,
            registerDragCallbacks: (callbacks) => {
                this.dragCallbacks = callbacks;
            },
            isBeingDragged: this.isBeingDragged,
            isCustomPosition: this.state.isCustomPosition,
        }, this.props);
        return React.createElement(Comp, props);
    },
});
export { draggableHOC as draggable };
