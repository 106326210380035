import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from './connect';
const shouldRenderKey = '__shouldRender__';
const withConditionalRender = (stores, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
shouldRender = (stores) => true) => (CompClass) => {
    class WithConditionalRender extends Component {
        render() {
            if (this.props[shouldRenderKey]) {
                return React.createElement(CompClass, Object.assign({}, _.omit(this.props, shouldRenderKey)));
            }
            return null;
        }
    }
    //@ts-expect-error
    WithConditionalRender.propTypes = {
        stores: PropTypes.object,
    };
    const mapStateToProps = (stateMapperArgs) => {
        return {
            [shouldRenderKey]: shouldRender(stateMapperArgs),
        };
    };
    return connect(stores, mapStateToProps)(WithConditionalRender);
};
export default withConditionalRender;
