var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { fittingTypes } from '../imageService/imageTransform';
const VECTOR_SHAPE_DEFAULT_STYLE = {
    strokeWidth: 4,
    opacity: 1,
    stroke: '#5E97FF',
    strokeOpacity: 1,
    enableStroke: false,
};
const VECTOR_SHAPE_COLOR_DEFAULT = {
    color1: '#242323',
};
const LEGACY_FIT_DISPLAY_MODE = 'legacyFit';
function getVectorImageDesign(previousDesign, previousInfo, newSvgType) {
    var _a;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { svgType } = previousInfo, previousAllColors = __rest(previousInfo, ["svgType"]);
    Object.assign(previousAllColors, previousDesign === null || previousDesign === void 0 ? void 0 : previousDesign.overrideColors);
    const previousStyle = previousDesign === null || previousDesign === void 0 ? void 0 : previousDesign.shapeStyle;
    const previousOpacity = (_a = previousStyle === null || previousStyle === void 0 ? void 0 : previousStyle.opacity) !== null && _a !== void 0 ? _a : VECTOR_SHAPE_DEFAULT_STYLE.opacity;
    const shapeColor = previousAllColors.color1 && !previousAllColors.color2
        ? previousAllColors.color1
        : VECTOR_SHAPE_COLOR_DEFAULT.color1;
    let design;
    switch (newSvgType) {
        case 'shape':
            design = {
                overrideColors: {
                    color1: shapeColor,
                },
                shapeStyle: Object.assign(Object.assign({}, VECTOR_SHAPE_DEFAULT_STYLE), previousStyle),
            };
            break;
        case 'tint':
            design = {
                overrideColors: {
                    color1: shapeColor,
                },
                shapeStyle: {
                    opacity: previousOpacity,
                },
            };
            break;
        case 'color':
            design = {
                overrideColors: null,
                shapeStyle: {
                    opacity: previousOpacity,
                },
            };
            break;
        case 'ugc':
            design = {
                overrideColors: null,
                shapeStyle: {
                    opacity: previousOpacity,
                },
            };
    }
    return design;
}
function resetVectorImageAspectRatio(editorAPI, compRef) {
    const compProps = editorAPI.components.properties.get(compRef);
    if (compProps.displayMode === fittingTypes.SCALE_TO_FIT ||
        compProps.displayMode === LEGACY_FIT_DISPLAY_MODE) {
        const newShapeAspectRatio = editorAPI.media.vectorImage.getSvgOriginalAspectRatio(compRef);
        editorAPI.components.layout.resetCompLayoutToAspectRatio(compRef, newShapeAspectRatio, true);
        editorAPI.components.properties.update(compRef, { displayMode: fittingTypes.SCALE_TO_FIT }, true);
    }
}
function replaceVectorImage(editorAPI, compRef, compData, title, newSvgId, callback) {
    const svgId = compData === null || compData === void 0 ? void 0 : compData.svgId;
    const svgInfo = editorAPI.media.vectorImage.getSvgInfoFromCache(svgId);
    // Cache the svg
    editorAPI.media.vectorImage.fetchSvg(newSvgId, () => { });
    // Fetch is async...
    editorAPI.dsActions.waitForChangesApplied(function () {
        var _a;
        // Update design by svg type
        const newSvgType = (_a = editorAPI.media.vectorImage.getSvgInfoFromCache(newSvgId)) === null || _a === void 0 ? void 0 : _a.svgType;
        const design = editorAPI.components.design.get(compRef);
        const newDesign = Object.assign(Object.assign({}, design), getVectorImageDesign(design, svgInfo, newSvgType));
        if (newSvgType.match(/^(color|tint|ugc)$/) !== null) {
            editorAPI.components.properties.update(compRef, { displayMode: 'fit' });
        }
        const altData = svgId !== newSvgId ? '' : compData.alt;
        const newData = Object.assign(Object.assign({}, compData), { svgId: newSvgId, title, alt: altData });
        editorAPI.components.data.update(compRef, newData, true);
        editorAPI.components.design.update(compRef, newDesign, true);
        // After layout, relayout
        editorAPI.dsActions.waitForChangesApplied(function () {
            // Reset aspect ratio by displayMode
            resetVectorImageAspectRatio(editorAPI, compRef);
            editorAPI.history.add('Change Vector Image');
            callback === null || callback === void 0 ? void 0 : callback(title, newSvgId);
        });
    });
}
export { replaceVectorImage };
