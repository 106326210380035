import _ from 'lodash';
import React, { Component } from 'react';
function fromEntries(pairs) {
    return pairs.reduce((acc, pair) => {
        acc[pair[0]] = pair[1];
        return acc;
    }, {});
}
export const withModules = (modules, Placeholder = () => null) => (CompClass) => class WithModules extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            loadedModules: {},
        };
    }
    componentDidMount() {
        const pairs = Object.entries(modules);
        Promise.all(pairs.map(([name, loadModule]) => loadModule().then((mod) => [name, mod]))).then((fetchedModules) => {
            this.setState({ loadedModules: fromEntries(fetchedModules) });
        });
    }
    render() {
        return _.isEmpty(this.state.loadedModules) ? (React.createElement(Placeholder, null)) : (React.createElement(CompClass, Object.assign({}, this.state.loadedModules, this.props)));
    }
};
