// @ts-nocheck
import _ from 'lodash';
const URL_REGEX = /vimeo\.com\/(\d+)/;
const verifier = (url) => _.isString(url) && URL_REGEX.test(url);
const getEmbeddedUrl = (url) => {
    const videoId = url.match(URL_REGEX)[1];
    return `//player.vimeo.com/video/${videoId}`;
};
const getUrlFromId = (videoId) => `https://vimeo.com/${videoId}`;
export { verifier, getEmbeddedUrl, getUrlFromId };
