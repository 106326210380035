import _ from 'lodash';
import validationExpressions from './validationExpressions';
import { CREDIT_CARD_KEYWORDS, PASSWORD_KEYWORDS } from './pwdKeywords';
function oldAndNewValueValidation(oldValue, newValue) {
    return !oldValue && newValue === '';
}
const textinputValidators = {
    /**
     * validation code: V1
     * @param val
     * @returns {boolean}
     */
    email(val) {
        if (!val) {
            return true;
        }
        return validationExpressions.validEmailReg.test(val);
    },
    /**
     * validation code: V2
     * @param val
     * @returns {boolean}
     */
    url(val) {
        return validationExpressions.validUrlReg.test(val);
    },
    /**
     * validation code: V3 (force https)
     * @param val
     * @returns {boolean}
     */
    urlEmbed(val) {
        return validationExpressions.validUrlEmbed.test(val);
    },
    /**
     * validation code: V4, V5 (this is both minimum and maximum)
     * @param min
     * @param max
     * @returns {Function}
     */
    range(min, max) {
        return function (val) {
            return !_.isUndefined(val) && val.length >= min && val.length <= max;
        };
    },
    /**
     * validation code: V5
     * @param val
     * @returns {boolean}
     */
    number(val) {
        return (!_.isEmpty(val) && validationExpressions.validNumericValueReg.test(val));
    },
    /**
     * validation code: V6
     * @param val
     * @returns {boolean}
     */
    videoUrl(val) {
        return validationExpressions.validVideoUrlReg.test(val);
    },
    /**
     * validation code: V6
     * @param val
     * @returns {boolean}
     */
    videoUrlDifferentFormats(val) {
        return (validationExpressions.validYouTubeLongUrlReg.test(val) ||
            validationExpressions.validYouTubeShortUrlReg.test(val) ||
            validationExpressions.validVimeoUrlReg.test(val) ||
            validationExpressions.validDailymotionUrlReg.test(val) ||
            validationExpressions.validFacebookUrlReg.test(val));
    },
    /**
     * validation code: V9
     * @param val
     * @returns {boolean}
     */
    pinterestPinLink(val) {
        return validationExpressions.validPinItUrl.test(val.trim().toLowerCase());
    },
    /**
     * validation code: V14
     * @param val
     * @returns {boolean}
     */
    pinterestFollowUrl(val) {
        return validationExpressions.validatePinterestUrl.test(val.trim());
    },
    /**
     * validation code: V19
     * @param val
     * @returns {boolean}
     */
    twitterUser(val) {
        return validationExpressions.validTwitterUser.test(val.trim().toLowerCase());
    },
    facebookLink(val) {
        return !validationExpressions.validFacebookPageId.test(val);
    },
    disqusId(val) {
        return validationExpressions.validDisqusId.test(val.toLowerCase());
    },
    notEmpty(val) {
        return !_.isEmpty(val);
    },
    noHTMLTags(val) {
        return !/<|>/.test(val);
    },
    byInvalidCharacters(invalidCharacters) {
        return function (val) {
            const regExp = new RegExp(invalidCharacters);
            return !regExp.test(val);
        };
    },
    rangeOfWords(min, max, delimiter) {
        return function (val) {
            const wordList = val && val.split(delimiter);
            return (!_.isUndefined(wordList) &&
                wordList.length >= min &&
                wordList.length <= max);
        };
    },
    notEmptyString(val) {
        return !/^\s*$/.test(val);
    },
    phone(val) {
        return validationExpressions.validPhoneReg.test(val);
    },
    internationalPhone(val) {
        return validationExpressions.validInternationalPhoneReg.test(val);
    },
    fullUrl(val) {
        return validationExpressions.validFullUrl.test(val);
    },
    noEmoji(val) {
        return !validationExpressions.validEmoji.test(val);
    },
    validateMaxLength(prevMaxLength, maxLengthVal) {
        // eslint-disable-next-line prefer-regex-literals
        const reg = new RegExp('^\\d+$');
        maxLengthVal = String(maxLengthVal);
        if (oldAndNewValueValidation(prevMaxLength, maxLengthVal)) {
            return true;
        }
        const newMaxLength = parseInt(maxLengthVal, 10);
        return (maxLengthVal.match(reg) && newMaxLength > 0 && newMaxLength < 1000 * 1000);
    },
    variableName(str) {
        return validationExpressions.validVariableName.test(str);
    },
    eventName(str) {
        return validationExpressions.validEventName.test(str);
    },
    securitySafePasswordName(value) {
        return !_.includes(CREDIT_CARD_KEYWORDS, value.trim().toLowerCase());
    },
    securitySafeInputName(value) {
        return (!_.includes(PASSWORD_KEYWORDS, value.trim().toLowerCase()) &&
            this.securitySafePasswordName(value));
    },
};
export { textinputValidators };
