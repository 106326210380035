const validationExpressions = {
    /**
     * @description a Regular Expression that test for a valid Url
     * note that this is more forgiving, in other words, it will allow 'some' invalid domains (e.g. youtu.1), but we don't care too much - that's the users problem already
     */
    validUrlReg: /^(?:(?:ftps?:|https?:)?\/\/)?(?:(?:[\u0400-\uA69F\w][\u0400-\uA69F\w-]*)?[\u0400-\uA69F\w]\.)+(?:[\u0400-\uA69Fa-z]+|\d{1,3})(?::[\d]{1,5})?(?:[/?#].*)?\S$/i,
    validFullUrl: /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/,
    validUrlEmbed: /^(https:\/\/)(?:(?:[\u0400-\uA69F\w][\u0400-\uA69F\w-]*)?[\u0400-\uA69F\w]\.)+(?:[\u0400-\uA69Fa-z]+|\d{1,3})(?::[\d]{1,5})?(?:[/?#].*)?\S$/,
    /**
     * @description a Regular Expression that test for a valid url characters which are allowed for page uri naming
     */
    invalidUrlCharacters: /[^A-Za-z0-9-]/g,
    /**
     * @description a Regular Expression that test for a valid Video Url - different formats
     */
    validYouTubeLongUrlReg: /(?:youtube\.com\/watch[^\s]*[\?&]v=)([\w-]+)/,
    validYouTubeShortUrlReg: /(?:youtu\.be\/)([\w-]+)/,
    validVimeoUrlReg: /vimeo\.com\/(\d+)$/,
    validDailymotionUrlReg: /dailymotion\.com\/(?:video|playlist)\/([^_/]+)/,
    validFacebookUrlReg: /facebook\.com\/.+\/videos\/[A-Za-z0-9._%-]*/,
    /**
     * @description a Regular Expression that test for a valid Video (Youtube or Vimeo) Url
     */
    validVideoUrlReg: /^(http:|https:|)\/\/(player.|www.)?(dailymotion\.com|facebook\.com|vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(.+\/videos\/|video\/|embed\/|watch\?v=|v\/|^\/)?([A-Za-z0-9._%-]*)(\&\S+)?/,
    /**
     * @description a Regular Expression that test for a valid Email
     */
    validEmailReg: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    validPinItUrl: /^https:\/\/?www\.pinterest\.com\/pin\/\d+\/*$/,
    validatePinterestUrl: /^(https?:\/\/)?www\.pinterest\.com\/[^&%$#*?\\_=+.!<>|]+\/?$/,
    validTwitterUser: /^@?[a-zA-Z0-9_%]{1,15}$/,
    validNumericValueReg: /^[0-9]+(\.[0-9]+)?$/,
    validFacebookPageId: /[^\w\.]/,
    validDisqusId: /^[a-zA-Z0-9_-]+$/,
    validPhoneReg: /^[^A-Za-z]+$/,
    validInternationalPhoneReg: /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/,
    validElementName: /^[A-Za-z][A-Za-z0-9\-\_\:\.]*$/,
    validVariableName: /^[a-zA-Z_$][\w$]*$/,
    validEventName: /^[a-zA-Z0-9]+$/,
    /**
     * @description a Regular Expression that test for valid emoji
     */
    validEmoji: /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/,
};
export default Object.freeze(validationExpressions);
