import _ from 'lodash';
import { textinputValidators } from '../validators/textinputValidators';
const WHATSAPP_API_URL = 'https://api.whatsapp.com/send?phone=';
const LinkTypes = {
    NO_LINK: 'NoLink',
    PAGE_LINK: 'PageLink',
    ANCHOR_LINK: 'AnchorLink',
    EXTERNAL_LINK: 'ExternalLink',
    DOCUMENT_LINK: 'DocumentLink',
    PHONE_LINK: 'PhoneLink',
    WHATSAPP_LINK: 'WhatsAppLink',
    ADDRESS_LINK: 'AddressLink',
    EMAIL_LINK: 'EmailLink',
    LOGIN_TO_WIX_LINK: 'LoginToWixLink',
    DYNAMIC_PAGE_LINK: 'DynamicPageLink',
    EDGE_ANCHOR_LINK: 'EdgeAnchorLinks',
    POPUP_LINK: 'PopupLink',
    FORM_SUBMIT_BUTTON_LINK: 'FormSubmitButtonLink',
    TPA_PAGE_LINK: 'TpaPageLink',
};
function sanitizeHash(str) {
    return str.replace('#', '');
}
const sanitizeWhatsappPhoneNumber = (str = '') => 
// eslint-disable-next-line prefer-regex-literals
str.replace(new RegExp('[+| |-]', 'g'), '');
function getAnchorLinkName(editorAPI, anchorLink) {
    if (anchorLink.anchorDataId === 'SCROLL_TO_TOP' ||
        anchorLink.anchorDataId === 'SCROLL_TO_BOTTOM') {
        return anchorLink.anchorName;
    }
    const pageId = _.has(anchorLink.pageId, 'id')
        ? anchorLink.pageId.id
        : anchorLink.pageId;
    const anchorDataItem = _.isPlainObject(anchorLink.anchorDataId)
        ? anchorLink.anchorDataId
        : editorAPI.data.getById(sanitizeHash(anchorLink.anchorDataId), pageId);
    if (anchorDataItem) {
        return anchorDataItem.name;
    }
    return anchorLink.anchorName || getPageTitle(editorAPI, anchorLink.pageId);
}
function getTpaPageLinkValue(editorAPI, linkData) {
    var _a;
    return (_a = linkData === null || linkData === void 0 ? void 0 : linkData.path) !== null && _a !== void 0 ? _a : getPageTitle(editorAPI, linkData.pageId);
}
function getLinkValueAsString(editorAPI, linkData, addPrefix, translate) {
    if (!linkData) {
        return '';
    }
    switch (linkData.type) {
        case LinkTypes.PAGE_LINK:
            return getPageLinkValue(editorAPI, linkData, addPrefix);
        case LinkTypes.ANCHOR_LINK:
            return getAnchorLinkValue(editorAPI, linkData, addPrefix);
        case LinkTypes.EMAIL_LINK:
            return getEmailLinkValue(editorAPI, linkData, addPrefix);
        case LinkTypes.PHONE_LINK:
            return getPhoneLinkValue(editorAPI, linkData, addPrefix);
        case LinkTypes.WHATSAPP_LINK:
            return getWhatsAppLinkValue(editorAPI, linkData);
        case LinkTypes.ADDRESS_LINK:
            return getWhatsAppLinkValue(editorAPI, linkData);
        case LinkTypes.DOCUMENT_LINK:
            return getDocumentLinkValue(editorAPI, linkData, addPrefix);
        case LinkTypes.EXTERNAL_LINK:
            return getExternalLinkValue(editorAPI, linkData);
        case LinkTypes.LOGIN_TO_WIX_LINK:
            return getLoginToWixLinkValue(editorAPI, linkData, addPrefix);
        case LinkTypes.DYNAMIC_PAGE_LINK:
            return getDynamicPageLinkValue(editorAPI, linkData, addPrefix, translate);
        case LinkTypes.FORM_SUBMIT_BUTTON_LINK:
            return getFormSubmitButtonLinkValue();
        case LinkTypes.TPA_PAGE_LINK:
            return getTpaPageLinkValue(editorAPI, linkData);
        default:
            throw Error(`Unknown Link Type: ${linkData.type}`);
    }
}
function getExternalLinkValue(editorAPI, linkData) {
    if (!textinputValidators.url(linkData.url)) {
        return '';
    }
    return linkData.url;
}
function getPageTitle(editorAPI, pageId) {
    if (pageId) {
        const pageData = _.isPlainObject(pageId)
            ? pageId
            : editorAPI.dsRead.pages.data.get(sanitizeHash(pageId));
        if (pageData) {
            return pageData.title || '';
        }
    }
    return '';
}
function getPageLinkValue(editorAPI, linkData, addPrefix) {
    const pageTitle = getPageTitle(editorAPI, linkData.pageId);
    if (addPrefix) {
        return `Page - ${pageTitle}`;
    }
    return pageTitle;
}
function getDynamicPageLinkValue(editorAPI, linkData, addPrefix, translate) {
    const routerData = editorAPI.dsRead.routers.get.byId(linkData.routerId);
    if (!routerData) {
        return '';
    }
    let route = routerData.prefix;
    if (linkData.innerRoute && linkData.innerRoute !== '/') {
        route = `${route}/${linkData.innerRoute === 'CURRENT_INNER_ROUTE'
            ? translate('Platform_Link_Menu_Anchor_Item_Option_Any_Item')
            : linkData.innerRoute}`;
    }
    if (addPrefix) {
        return `Page Prefix - ${route}`;
    }
    return route;
}
function getEmailLinkValue(editorAPI, linkData, addPrefix) {
    const mailAddress = linkData.recipient || '';
    if (addPrefix) {
        return `Email - ${mailAddress}`;
    }
    return mailAddress;
}
function getPhoneLinkValue(editorAPI, linkData, addPrefix) {
    const phoneNumber = linkData.phoneNumber || '';
    if (addPrefix) {
        return `tel:${phoneNumber}`;
    }
    return phoneNumber;
}
function getWhatsAppLinkValue(editorAPI, linkData) {
    const phoneNumber = sanitizeWhatsappPhoneNumber(linkData.phoneNumber);
    return `${WHATSAPP_API_URL}${phoneNumber}`;
}
function getDocumentLinkValue(editorAPI, linkData, addPrefix) {
    if (addPrefix) {
        return `Document - ${linkData.name}`;
    }
    return linkData.name;
}
function getLoginToWixLinkValue(editorAPI, linkData, addPrefix) {
    if (addPrefix) {
        return 'Login / Signup Dialog';
    }
    return linkData.postLoginUrl;
}
function getAnchorLinkValue(editorAPI, linkData, addPrefix) {
    const pageName = getPageTitle(editorAPI, linkData.pageId);
    const anchorName = getAnchorLinkName(editorAPI, linkData);
    if (addPrefix) {
        return `Anchor (${anchorName}${pageName ? `, Page: ${pageName}` : ''})`;
    }
    return anchorName;
}
function getFormSubmitButtonLinkValue() {
    return 'Form Submit Button';
}
function getAnchors(editorAPI) {
    return _.map(editorAPI.components.get.byType('wysiwyg.common.components.anchor.viewer.Anchor'), (anchorRef) => {
        const data = editorAPI.components.data.get(anchorRef);
        const pageId = editorAPI.components.getPage(anchorRef).id;
        return {
            anchorName: data.name,
            pageId: `#${pageId}`,
            anchorDataId: `#${data.id}`,
            y: editorAPI.components.layout.get(anchorRef).y,
        };
    });
}
function getCleanPageId(pageId) {
    return _.startsWith(pageId, '#') ? pageId.substring(1) : pageId;
}
function getPagesForLinkPanel(editorAPI) {
    const menuItems = _.flatMap(editorAPI.mainMenu.getMenu(true), (menuItem) => [
        menuItem,
        ...menuItem.items,
    ]);
    const pagesData = editorAPI.dsRead.pages.getPagesData();
    const pagesDataMap = _.keyBy(pagesData, (page) => page.id);
    const pagesSortedByMenuItemsOrder = _.reduce(_.filter(menuItems, (item) => _.get(item, 'link.type') === LinkTypes.PAGE_LINK), (acc, menuItem) => {
        const pageId = getCleanPageId(menuItem.link.pageId);
        const page = pagesDataMap[pageId];
        if (page) {
            //@ts-expect-error
            acc.push(pagesDataMap[pageId]);
            delete pagesDataMap[pageId];
        }
        return acc;
    }, []);
    const pagesThatAreNotInMenu = _.filter(pagesDataMap, (page) => !editorAPI.dsRead.tpa.isPageMarkedAsHideFromMenu(page.tpaApplicationId, page.tpaPageId));
    return _.concat(pagesSortedByMenuItemsOrder, pagesThatAreNotInMenu);
}
function getLinkPanelProps(editorAPI, props) {
    const anchors = getAnchors(editorAPI);
    const defaultProps = {
        bi: {
            selectedComponentType: editorAPI.selection.getSelectedComponentType(),
        },
        data: {
            pages: getPagesForLinkPanel(editorAPI),
            anchors,
            currentPageId: editorAPI.dsRead.pages.getPrimaryPageId(),
            popups: editorAPI.pages.popupPages.getDataListOrdered(),
        },
        createDefaultData: editorAPI.dsRead.data.createItem,
    };
    props = _.merge(defaultProps, props);
    if (!_.has(props, 'visibleSections.PhoneLink')) {
        _.set(props, 'visibleSections.PhoneLink', true);
    }
    return props;
}
export { LinkTypes, getLinkPanelProps, getAnchorLinkName, getLinkValueAsString, };
