export const CREDIT_CARD_KEYWORDS = [
    'credit card number',
    'numéro de la carte bancaire',
    'Kreditkartennummer',
    'Número de tarjeta de crédito',
    'Número do cartão de crédito',
    'номер кредитной карты',
    'クレジットカード番号',
    '신용카드 번호',
    'Numero carta di credito',
    'numer karty kredytowej',
    'kredi kartı numarası',
    'creditcardnummer',
    'kredittkortnummer',
    'kreditkortsnummer',
    'kreditkortnummer',
    'číslo kreditní karty',
    'หมายเลขบัตรเครดิต',
    '信用卡號',
    'passcode',
    'code',
    'Sicherheitscode',
    'Contraseña',
    'Código de acesso',
    'секретный код',
    'パスワード',
    '비밀번호',
    'Codice',
    'kod dostępu',
    'parola',
    'wachtwoord',
    'sikkerhetskode',
    'lösenkod',
    'adgangskode',
    'přístupový kód',
    'รหัสผ่าน',
    '密碼',
    'paypal number',
    'numéro Paypal',
    'PayPal-Nummer',
    'Número de Paypal',
    'Número do Paypal',
    'номер paypal',
    'PayPal 番号',
    'PayPal 번호',
    'Numero Pyapal',
    'numer PayPal',
    'paypal numarası',
    'paypal nummer',
    'PayPal-nummer',
    'PayPal-nummer',
    'PayPal-nummer',
    'paypal číslo',
    'หมายเลข Paypal',
    'Paypal 號碼',
    'paypal code',
    'code Paypal',
    'PayPal-Code',
    'Código de Paypal',
    'Código do Paypal',
    'код paypal',
    'PayPal コード',
    'PayPal 코드',
    'Codice Paypal',
    'kod PayPal',
    'paypal kodu',
    'paypal code',
    'PayPal-kode',
    'PayPal-kod',
    'PayPal-kode',
    'paypal kód',
    'รหัส Paypal',
    'Paypal 代碼',
    'bank account number',
    'numéro de compte bancaire',
    'Kontonummer',
    'Número de cuenta bancaria',
    'Número da conta bancária',
    'номер банковского счета',
    '銀行口座番号',
    '은행 계좌 번호',
    'Numero di conto bancario',
    'numer konta bankowego',
    'banka hesap numarası',
    'bankrekeningnummer',
    'bankkontonummer',
    'bankkontonummer',
    'kontonummer',
    'číslo bankovního účtu',
    'หมายเลขบัญชีธนาคาร',
    '銀行號碼',
    'internet bank',
    'Gcash',
    'banque en ligne',
    'Online-Bank',
    'Banco online',
    'Banco online',
    'интернет-банк',
    'ネット銀行',
    '인터넷 뱅크 or 인터넷 은행',
    'Banca online',
    'bank internetowy',
    'online bankacılık',
    'internetbank',
    'internettbank',
    'internetbank',
    'webbank',
    'internetbanking',
    'อินเตอร์เน็ตแบงก์กิ้ง',
    '網路銀行',
    'login code',
    "code d'accès",
    'Registrierungscode',
    'Código de inicio',
    'Código de login',
    'регистрационный код',
    'ログインコード',
    '로그인 코드',
    "Codice d'accesso",
    'kod logowania',
    'giriş kodu',
    'login code',
    'innloggingskode',
    'inloggningskod',
    'log ind kode',
    'přihlašovací kód',
    'รหัสล็อกอิน',
    '登入代碼',
    'product key',
    'clé du produit',
    'Produktschlüssel',
    'Clave del producto',
    'Código de ativação',
    'ключ продукта',
    'プロダクトキー',
    '제품 키 or 프로덕트 키',
    'Codice prodotto',
    'klucz produktu',
    'ürün anahtarı',
    'productcode',
    'produktnøkkel',
    'produktnyckel',
    'produktnøgle',
    'product key',
    'หมายเลขผลิตภัณฑ์',
    '產品序號',
    'Senha de acesso',
    'номер карты',
    'číslo platební karty',
    'Paypal 帳號',
    'Codigo de segurança',
    'код доступа',
    'číslo debetní karty',
    'Paypal 帳戶',
    'Palavra-passe',
    'интернет банк',
    '銀行帳戶',
    '銀行帳號',
    'cvv',
    'ccv',
    'paysafecard',
    'paysafe',
    'moneygram',
    'Paypal',
];
export const PASSWORD_KEYWORDS = [
    'fjalëkalim',
    'pasahitza',
    'пароль',
    'lozinka',
    'парола',
    'contrasenya',
    'heslo',
    'adgangskode',
    'wachtwoord',
    'parool',
    'salasana',
    'mot de passe',
    'contrasinal',
    'passwort',
    'jelszó',
    'lykilorð',
    'focal faire',
    'parole',
    'slaptažodis',
    'лозинка',
    'hasło',
    'senha',
    'parola',
    'geslo',
    'contraseña',
    'lösenord',
    'cyfrinair',
    'פּאַראָל',
    'գաղտնաբառ',
    'parol',
    'পাসওয়ার্ড',
    '密码',
    '密碼',
    'დაგავიწყდათ',
    'પાસવર્ડ',
    'पासवर्ड',
    'パスワード',
    'ಪಾಸ್ವರ್ಡ್',
    'ការពាក្យសម្ងាត់',
    '암호',
    'ລະຫັດຜ່ານ',
    'password',
    'စကားဝှက်ကို',
    'මුරපදය',
    'гузарвожа',
    'கடவுச்சொல்',
    'పాస్వర్డ్',
    'รหัสผ่าน',
    'پاس ورڈ',
    'mật khẩu',
    'كلمه السر',
    'סיסמה',
    'ססמה',
    'ססמא',
    'סיסמא',
    'کلمه عبور',
    'wagwoord',
    'achinsinsi',
    'kalmar sirri',
    'paswọọdụ',
    'phasewete',
    'ọrọigbaniwọle',
    'iphasiwedi',
    'sandi',
    'tenimiafina',
    'kupuhipa',
    'pasvorto',
    'modpas',
    'contraseña',
    'contrasena',
];
