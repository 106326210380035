// @ts-nocheck
import _ from 'lodash';
const URL_REGEX = /^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:(?:dailymotion\.com(?:\/embed)?\/video)|dai\.ly)\/([a-zA-Z0-9]+)(?:_[\w_-]+)?$/;
const verifier = (url) => _.isString(url) && URL_REGEX.test(url);
const getEmbeddedUrl = (url) => {
    const videoId = url.match(URL_REGEX)[1];
    return `//www.dailymotion.com/embed/video/${videoId}?controls=1&sharing-enable=0&ui-logo=0&ui-start-screen-info=0`;
};
const getUrlFromId = (videoId) => `http://dailymotion.com/video/${videoId}`;
export { verifier, getEmbeddedUrl, getUrlFromId };
