var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
//@ts-nocheck
let geocoder;
const latLngRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
const getGeocoder = () => {
    if (!geocoder) {
        geocoder = new window.google.maps.Geocoder();
    }
    return geocoder;
};
// eslint-disable-next-line consistent-return
const getGeocoderOkStatus = () => {
    if (window.google && window.google.maps) {
        return window.google.maps.GeocoderStatus.OK;
    }
};
const isGeocodingServiceLoaded = () => !!(window.google && window.google.maps && geocoder);
const loadGeocodingService = (callback) => {
    window.onGeocodingServiceLoaded = function () {
        // eslint-disable-next-line no-use-before-define
        if (scriptChild) {
            // eslint-disable-next-line no-use-before-define
            window.document.body.removeChild(scriptChild);
        }
        callback();
    };
    const script = window.document.createElement('script');
    script.type = 'text/javascript';
    script.src =
        '//maps.googleapis.com/maps/api/js?key=AIzaSyDAGW1U0Vmj6sKrGKqTngRvI78Ih48ZBig&sensor=true&' +
            'callback=onGeocodingServiceLoaded';
    const scriptChild = window.document.body.appendChild(script);
};
/**
 * - Calls google api (async) to transform address to lat/long data fields.
 * - Presents a validation error in case no such address is found
 * @param address
 * @param onSuccess
 * @param onError
 */
const getGeoLocationFromAddress = (address, onSuccess, onError, locationService) => __awaiter(void 0, void 0, void 0, function* () {
    if (latLngRegex.test(address)) {
        const [lat, lng] = address.split(', ');
        if (lat && lng) {
            const location = {
                address,
                latitude: Number(lat),
                longitude: Number(lng),
            };
            onSuccess(location);
            return;
        }
    }
    if (locationService) {
        const { searchResults } = yield locationService.search({
            query: address,
        });
        if (searchResults === null || searchResults === void 0 ? void 0 : searchResults.length) {
            const [primaryResult] = searchResults;
            if (primaryResult.address) {
                const { address: { formattedAddress, geocode }, } = primaryResult;
                const location = {
                    address: formattedAddress,
                    latitude: geocode === null || geocode === void 0 ? void 0 : geocode.latitude,
                    longitude: geocode === null || geocode === void 0 ? void 0 : geocode.longitude,
                };
                return onSuccess(location);
            }
        }
        return onError('Validation_V13_Google_Maps');
    }
    getGeocoder().geocode({ address }, (results, status) => {
        if (status === getGeocoderOkStatus()) {
            const locationCoordinates = results[0].geometry.location;
            const formattedAddress = results[0].formatted_address;
            const location = {
                address: formattedAddress,
                latitude: locationCoordinates.lat(),
                longitude: locationCoordinates.lng(),
            };
            onSuccess(location);
        }
        else {
            onError('Validation_V13_Google_Maps');
        }
    });
});
const validateAddress = (address, onSuccess, onError, locationService) => {
    const convertAddressToGeoLocation = () => {
        getGeoLocationFromAddress(address, onSuccess, onError, locationService);
    };
    if (isGeocodingServiceLoaded()) {
        convertAddressToGeoLocation();
    }
    else {
        loadGeocodingService(convertAddressToGeoLocation);
    }
};
export { validateAddress };
