import { ErrorReporter } from '@wix/editor-error-reporter';
import equal from 'fast-deep-equal/es6/react';
export function createPropsDeepEqual(componentName) {
    let errSent = false;
    return (a, b) => {
        try {
            return equal(a, b);
        }
        catch (error) {
            if (error instanceof Error &&
                (error.message || '').match(/stack|recursion/i)) {
                // warn on circular references, don't crash
                // browsers give this different errors name and messages:
                // chrome/safari: "RangeError", "Maximum call stack size exceeded"
                // firefox: "InternalError", too much recursion"
                // edge: "Error", "Out of stack space"
                if (!errSent) {
                    ErrorReporter.captureMessage('circular refs in propsDeepEqual', {
                        extra: {
                            componentName,
                            stack: error.stack,
                        },
                    });
                    errSent = true;
                }
                if (process.env.NODE_ENV === 'production') {
                    //eslint-disable-next-line no-console
                    console.error(`circular refs in propsDeepEqual (${componentName}), report this to editor team!`);
                    return false;
                }
            }
            // some other error. we should definitely know about these
            throw error;
        }
    };
}
