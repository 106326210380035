import { isEditorWindow } from './isEditorWindow';
export function isResponsiveEditor() {
    if (!isEditorWindow(window)) {
        return false;
    }
    return window.isResponsive === true;
}
/**
 * @deprecated use `shell.getAPI(EditorParamsApi).isInsideEditorX`
 */
export function isOnEditorX() {
    if (!isEditorWindow(window)) {
        return false;
    }
    return window.commonConfig.brand === 'editorx';
}
export function isOnWixStudio() {
    if (!isEditorWindow(window)) {
        return false;
    }
    return window.commonConfig.brand === 'studio';
}
