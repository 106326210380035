var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _ from 'lodash';
import { connect, STORES } from '../hoc/connect';
import withBeforeFirstRender from '../hoc/withBeforeFirstRender';
import consts from './stylableChangeConsts';
const { STYLESHEET_KEY_PATH, VARIANT_CLASSNAME, COMPONENT_PREFIX } = consts;
export const getVariantPath = (variant) => `/${variant}.st.css`;
export const getPanelId = (compType) => {
    const prefix = COMPONENT_PREFIX.find((prefix) => compType.startsWith(prefix));
    return prefix ? compType.slice(prefix.length) : compType;
};
export const getSelectedComponents = (selectedComponentOrComponents) => Array.isArray(selectedComponentOrComponents)
    ? selectedComponentOrComponents
    : [selectedComponentOrComponents];
const mobileMediaQueryVarName = 'mobileMediaQuery';
const MOBILE_MEDIA_QUERY = `@media value(${mobileMediaQueryVarName})`;
let variantPointerOnLoad;
let selectedComponentOnLoad;
/**
 *
 * @param editorAPI
 * @param selectedComponent - CompRef
 * @returns
 */
function getCascadePaths(editorAPI, selectedComponent) {
    // Get cascade styleSheet paths from variant array:
    const cascadeVariants = editorAPI.components.dangerouslyGetCascadeVariants(selectedComponent);
    if (cascadeVariants) {
        const cascadeStyleIds = cascadeVariants.reduce((accum, variant) => {
            const variantStyleItem = editorAPI.dsRead.components.style.get(variant);
            if (variantStyleItem) {
                accum.push(variantStyleItem.id);
            }
            return accum;
        }, []);
        cascadeStyleIds.reverse();
        return cascadeStyleIds.map((styleId) => getVariantPath(styleId));
    }
    return undefined;
}
const hasResponsiveLayout = (editorAPI, compRef) => {
    var _a;
    return Boolean(compRef && ((_a = editorAPI.dsRead.components.responsiveLayout) === null || _a === void 0 ? void 0 : _a.get(compRef)));
};
const shouldUseOriginalStyleItemOnly = (selectedComponent, editorAPI) => {
    const isMobileEditor = editorAPI.isMobileEditor();
    return isMobileEditor && !hasResponsiveLayout(editorAPI, selectedComponent);
};
const getStyleDataItem = (selectedComponent, editorAPI, useOriginalStyleItem = false) => {
    return useOriginalStyleItem
        ? editorAPI.documentServices.components.style.get(selectedComponent)
        : editorAPI.components.style.get(selectedComponent);
};
export const withStylableChange = () => {
    const mapStateToProps = ({ editorAPI }, props) => {
        const { multiSelectedComponents } = props;
        const selectedComponents = getSelectedComponents(props.selectedComponent);
        const selected = multiSelectedComponents || selectedComponents;
        const { components } = editorAPI.documentServices;
        const isMobileEditor = editorAPI.isMobileEditor();
        const stylableEditor = components.stylable.getEditorInstance();
        const selectedComponent = _.head(selected);
        const useOriginalStyleItem = shouldUseOriginalStyleItemOnly(selectedComponent, editorAPI);
        const styleDataItem = getStyleDataItem(selectedComponent, editorAPI, useOriginalStyleItem);
        const styleId = styleDataItem.id;
        const cascadePaths = useOriginalStyleItem
            ? undefined
            : getCascadePaths(editorAPI, selectedComponent);
        const withSelection = (callback) => {
            const selection = stylableEditor.getSelection(0);
            return selection ? callback(selection) : null;
        };
        const updateCompStyle = (newCss, compRef, styleDefinition = styleDataItem) => editorAPI.components.stylable.update(compRef, _.defaultsDeep(_.set({}, STYLESHEET_KEY_PATH, stylableEditor.getMinimalCss(newCss)), styleDefinition));
        const updateStyle = (newCss) => {
            if (newCss && selected.length === 1) {
                const compRef = selected[0];
                return updateCompStyle(newCss, compRef, editorAPI.components.style.get(compRef));
            }
            selected.forEach((compRef) => {
                var _a;
                const styleDefinition = components.style.get(compRef);
                const path = getVariantPath(styleDefinition.id);
                const selection = stylableEditor === null || stylableEditor === void 0 ? void 0 : stylableEditor.getSelection(0);
                const stylesheet = (_a = selection === null || selection === void 0 ? void 0 : selection.stylableDriver) === null || _a === void 0 ? void 0 : _a.getStylesheet(path);
                if (stylesheet) {
                    updateCompStyle(stylesheet.source, compRef, styleDefinition);
                }
            });
        };
        const partialChangeCallback = (changes) => {
            var _a;
            if (multiSelectedComponents) {
                const selectedComponentPaths = multiSelectedComponents.map((compRef) => {
                    const styleDefinition = editorAPI.components.style.get(compRef);
                    return getVariantPath(styleDefinition.id);
                });
                const stylesheetChanges = [];
                selectedComponentPaths.forEach((stylesheetPath) => {
                    changes.forEach((change) => stylesheetChanges.push(Object.assign(Object.assign({}, change), { stylesheetPath })));
                });
                (_a = stylableEditor === null || stylableEditor === void 0 ? void 0 : stylableEditor.getSelection(0)) === null || _a === void 0 ? void 0 : _a.applyChangesToStylesheets(stylesheetChanges);
            }
            return !!multiSelectedComponents;
        };
        const onQuickChange = (selector, declarationMap) => {
            selected.forEach((compRef) => components.stylable.quickChange(compRef, selector, declarationMap));
        };
        const onRevertQuickChange = () => {
            selected.forEach((compRef) => components.stylable.revertQuickChange(compRef));
        };
        const addToUndoRedoStack = () => {
            const { STYLE_CHANGE_STACK_LABEL } = consts;
            const isMobileEditor = editorAPI.isMobileEditor();
            editorAPI.history.debouncedAdd(`${STYLE_CHANGE_STACK_LABEL} : ${styleId}`, {
                currentPage: editorAPI.pages.getCurrentPageId(),
                isMobileEditor,
            });
        };
        const formatIndexCssVar = (varName, componentName) => `--${componentName}-${varName}`;
        return {
            stylableEditor,
            variant: styleId,
            loadVariant: (compType, callbacks = {}) => {
                // change this API. callbacks shouldn't be passed like this, and loadVariant should be called from withStylableChange instead of from the panel
                // TODO after using new ReloadVariant API we can get rid of handleOnChange and its derivatives.
                const { handleOnChange } = callbacks;
                if (handleOnChange) {
                    stylableEditor.stylableDriver.registerBuildHook(handleOnChange);
                }
                stylableEditor.load({
                    path: getVariantPath(styleId),
                    id: getPanelId(compType),
                    variant: VARIANT_CLASSNAME,
                    scope: isMobileEditor ? MOBILE_MEDIA_QUERY : undefined,
                    onQuickChange,
                    onRevertQuickChange,
                    inputPaths: cascadePaths,
                });
                variantPointerOnLoad =
                    editorAPI.components.getSelectedCompVariantPointerIfExists();
                selectedComponentOnLoad = selectedComponent;
                withSelection((selection) => {
                    selection.changeDriver.setOnStylesheetCommit(addToUndoRedoStack);
                    if (multiSelectedComponents) {
                        selection.changeDriver.setOnPartialChange(partialChangeCallback);
                    }
                });
            },
            unloadVariant: (handleOnChange) => {
                // TODO after using new ReloadVariant API we can get rid of handleOnChange and its derivatives.
                if (handleOnChange) {
                    stylableEditor.stylableDriver.unregisterBuildHook(handleOnChange);
                }
                withSelection(
                // TODO: make this official api to remove the callback
                (selection) => {
                    selection.changeDriver.setOnStylesheetCommit(undefined);
                });
                editorAPI.components.stylable.removeRefIfEmptyStyle(selectedComponentOnLoad, variantPointerOnLoad); //using saved variant pointer to prevent "removeRef" on new selected comp. Happens when clicking outside panel. see https://jira.wixpress.com/browse/STYL-667
            },
            getSelectorDeclarations: (selector) => withSelection((selection) => selection.getSelectorDeclarations(selector)),
            setSelectorDeclarations: (selector, declarations) => withSelection((selection) => selection.setSelectorDeclarations(selector, declarations)),
            ensureImportedCssVars: (varNames) => withSelection((selection) => selection.ensureImportedCssVars(varNames, formatIndexCssVar)),
            blockCommits: () => withSelection((selection) => selection.changeDriver.startBlockingCommits()),
            releaseCommits: () => withSelection((selection) => selection.changeDriver.releaseBlockingCommits()),
            updateStyle,
            updateStyleFromSourceCss: () => withSelection((selection) => updateStyle(selection.getSourceCSS())),
            getStyleCss: () => _.get(styleDataItem, STYLESHEET_KEY_PATH),
            addToUndoRedoStack,
            createEmptyStyle: () => __awaiter(void 0, void 0, void 0, function* () {
                editorAPI.components.stylable.createEmptyStyle(selectedComponent);
                yield new Promise((resolve) => {
                    editorAPI.waitForChangesApplied(resolve);
                });
            }),
        };
    };
    return _.flow(withBeforeFirstRender(({ createEmptyStyle }) => createEmptyStyle()), // TODO can remove after using withReloadVariant in wix-ui-santa/ee components
    connect(STORES.EDITOR_API, mapStateToProps));
};
