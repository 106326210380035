import React, { Component } from 'react';
import { isEqual } from 'lodash';
import { object, func } from 'prop-types';
import { ErrorReporter } from '@wix/editor-error-reporter';
export const measuring = (Comp) => { var _a; return _a = class Measuring extends Component {
        constructor() {
            super(...arguments);
            this.state = {
                measurements: {},
            };
            this.updateMeasurements = (measurements) => {
                if (!isEqual(measurements, this.state.measurements)) {
                    this.setState({ measurements });
                }
            };
        }
        componentDidCatch(error, info) {
            // eslint-disable-next-line no-console
            console.error('Measuring componentDidCatch', error, info);
            const throwingComponent = Comp.displayName;
            ErrorReporter.captureException(error, {
                tags: {
                    throwingComponent,
                    measuringWrapper: true,
                },
                extra: {
                    errorInfo: info,
                },
            });
        }
        render() {
            return (React.createElement(Comp, Object.assign({}, this.props, { measurements: this.props.measurements || this.state.measurements, reportMeasurements: this.props.reportMeasurements || this.updateMeasurements })));
        }
    },
    _a.propTypes = {
        measurements: object,
        reportMeasurements: func,
    },
    _a; };
