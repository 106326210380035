var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// import _ from 'lodash'
import React, { Component } from 'react';
const withBeforeFirstRender = (beforeRenderTask = () => __awaiter(void 0, void 0, void 0, function* () { })) => (CompClass) => {
    class WithBeforeFirstRender extends Component {
        constructor(props) {
            super(props);
            this.runBeforeRenderTask = (props) => __awaiter(this, void 0, void 0, function* () {
                yield beforeRenderTask(props);
                this.setState({ ready: true });
            });
            this.state = { ready: false };
        }
        componentDidMount() {
            this.runBeforeRenderTask(this.props);
        }
        render() {
            return this.state.ready ? React.createElement(CompClass, Object.assign({}, this.props)) : null; //eslint-disable-line react/prop-types
        }
    }
    return WithBeforeFirstRender;
};
export default withBeforeFirstRender;
