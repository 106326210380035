import _ from 'lodash';
import React from 'react';
import createReactClass from 'create-react-class';
import * as redux from 'redux';
import * as reactRedux from 'react-redux';
import PropTypes from 'prop-types';
import { wrapDisplayName } from '../../recompose';
import { createPropsDeepEqual } from '../propsDeepEqual';
import defaultWithErrorBoundary from '../errorBoundaryComponent';
import { withConnectedErrorBoundary } from './withConnectedErrorBoundary';
export const STORES = {
    STATE_ONLY: ['state'],
    DS_ONLY: ['dsRead'],
    EDITOR_API: ['state', 'dsRead', 'editorAPI', 'host'],
    STATE_AND_DS: ['state', 'dsRead'],
    MOUSE_OPS: ['state', 'dsRead', 'editorAPI', 'mouseops', 'host'],
};
const dsOnly = (next, prev) => next.state.viewerRendersCounter === prev.state.viewerRendersCounter;
const dsAndState = (next, prev) => dsOnly(next, prev) && next.state === prev.state;
const makeMapStateToProps = (storesArr, mapStateToProps) => {
    if (!_.isFunction(mapStateToProps)) {
        return null;
    }
    // NOTE: the number of declared mapStateToProps function parameters affects when it will be called.
    // https://react-redux.js.org/api/connect#parameters
    let dependsOnOwnProps = true;
    if (mapStateToProps.dependsOnOwnProps === false) {
        dependsOnOwnProps = false;
    }
    else if (mapStateToProps.length === 1) {
        dependsOnOwnProps = false;
    }
    const selector = (combinedState, ...mbOwnProps) => {
        //should use spread in case arguments.length === 1;
        const mappingDependencies = _.pick(combinedState, storesArr);
        return _.isFunction(mapStateToProps)
            ? mapStateToProps(mappingDependencies, ...mbOwnProps)
            : {};
    };
    selector.dependsOnOwnProps = dependsOnOwnProps;
    return selector;
};
function isClassComponent(component) {
    var _a;
    return (typeof component === 'function' && !!((_a = component.prototype) === null || _a === void 0 ? void 0 : _a.isReactComponent));
}
const wrapForTests = (Comp) => class TestedComp extends React.Component {
    render() {
        return React.createElement(Comp, this.props);
    }
};
export const connect = (storesArr, mapStateToProps, mapDispatchToProps, mergeProps, pure = true, withErrorBoundary = defaultWithErrorBoundary) => (Component) => {
    if (process.env.NODE_ENV === 'test') {
        if (!isClassComponent(Component)) {
            // we need ref for tests, but fc dont have ref
            Component = wrapForTests(Component);
        }
    }
    const propsDeepEqual = createPropsDeepEqual(Component.displayName);
    const dsConnected = _.includes(storesArr, 'dsRead');
    const stateConnected = _.includes(storesArr, 'state');
    const editorAPIConnected = _.includes(storesArr, 'editorAPI');
    const mouseConnected = _.includes(storesArr, 'mouseops');
    let areStatePropsEqual = propsDeepEqual;
    if (!pure) {
        areStatePropsEqual = () => false; // if pure=false, we respect areStatesEqual logic, but still rerender if state mapper returns same data
    }
    const ConnectedComponent = reactRedux.connect(makeMapStateToProps(storesArr, mapStateToProps), mapDispatchToProps, mergeProps, {
        areStatesEqual(next, prev) {
            var _a, _b, _c, _d;
            const nextIsDrag = (_b = (_a = next.state) === null || _a === void 0 ? void 0 : _a.mouseActions) === null || _b === void 0 ? void 0 : _b.dragInProgress;
            const prevIsDrag = (_d = (_c = prev.state) === null || _c === void 0 ? void 0 : _c.mouseActions) === null || _d === void 0 ? void 0 : _d.dragInProgress;
            if (nextIsDrag && prevIsDrag && !mouseConnected) {
                return true;
            }
            if ((dsConnected && stateConnected) || editorAPIConnected) {
                return dsAndState(next, prev);
            }
            else if (dsConnected) {
                return dsOnly(next, prev);
            }
            return next.state === prev.state;
        },
        areStatePropsEqual,
        areOwnPropsEqual: propsDeepEqual,
        forwardRef: true,
    })(Component);
    const ResultComponent = typeof withErrorBoundary === 'function'
        ? withConnectedErrorBoundary(ConnectedComponent, withErrorBoundary)
        : ConnectedComponent;
    // Looks like it's a community standard:
    // https://github.com/reduxjs/react-redux/blob/791e00945558eb1586d719b51666493276c8d63d/src/components/connect.tsx#L805
    // https://github.com/wix-private/santa-editor/blob/536d4d79e5c04146d8459a27c018cd46b6ff5931/santa-editor/packages/testUtils/index.ts#L62
    ResultComponent.WrappedComponent = Component;
    return ResultComponent;
};
export const connectOld = (storesArr, mapStateToProps = _.noop, mapDispatchToProps = _.noop, shouldHandleStateChanges = false) => (Component) => {
    const selectorFactory = (dispatch, { dsRead, editorAPI }) => (state, ownProps) => {
        const mappingDependencies = _.pick({ dsRead, state, editorAPI }, storesArr);
        const stateProps = mapStateToProps(mappingDependencies, ownProps);
        const dispatchProps = _.isFunction(mapDispatchToProps)
            ? mapDispatchToProps(dispatch, ownProps)
            : redux.bindActionCreators(mapDispatchToProps, dispatch);
        return _.assign({}, ownProps, stateProps, dispatchProps);
    };
    return createReactClass({
        displayName: wrapDisplayName(Component, 'Connect'),
        contextTypes: {
            dsRead: PropTypes.object,
            editorAPI: PropTypes.object,
        },
        componentWillMount() {
            this.connectedComponentClass = reactRedux.connectAdvanced(selectorFactory, {
                methodName: 'connect',
                shouldHandleStateChanges,
                dsRead: this.context.dsRead,
                editorAPI: this.context.editorAPI,
            })(Component);
        },
        render() {
            return React.createElement(this.connectedComponentClass, this.props);
        },
    });
};
