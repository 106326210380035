import React from 'react';
import * as reactRedux from 'react-redux';
export const withConnectedErrorBoundary = (Component, withErrorBoundary) => {
    let ErrorBoundary;
    // wrapper for:
    // - passing `store.subscribe` as a `subscribeUpdates` function to the `withErrorBoundary`
    // - forwarding the `ref` as a `forwardedRef` to the wrapped component
    const renderErrorBoundary = (props, ref) => {
        const store = reactRedux.useStore();
        ErrorBoundary =
            ErrorBoundary ||
                withErrorBoundary(Component, {
                    subscribeUpdates: store.subscribe,
                });
        return React.createElement(ErrorBoundary, Object.assign(Object.assign({}, props), { forwardedRef: ref }));
    };
    return React.forwardRef(renderErrorBoundary);
};
