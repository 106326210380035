// @ts-nocheck
import _ from 'lodash';
const URL_REGEX = /\.(mp4|og[gv]|webm|mov|m4v)($|\?)/i;
const verifier = (url) => {
    if (_.isString(url)) {
        return URL_REGEX.test(url);
    }
    else if (_.isArray(url)) {
        return url.some((item) => URL_REGEX.test(item));
    }
    return false;
};
export { verifier };
