/**
 * Created by eitanr on 6/12/14.
 */
import coreUtilsLib from '@wix/santa-core-utils';
function forceChangeEditorUrlTo(url) {
    window.onbeforeunload = null;
    window.location = url;
}
function isExternalUrl(url) {
    return /(^https?)|(^data)/.test(url);
}
function isQA() {
    return (coreUtilsLib.urlUtils.getParameterByName('isqa') === 'true' ||
        coreUtilsLib.urlUtils.getParameterByName('isQA') === 'true' // don't use isQA anymore
    );
}
function noAppSave() {
    return coreUtilsLib.urlUtils.getParameterByName('noAppSave') === 'true';
}
export default {
    forceChangeEditorUrlTo,
    addProtocolIfMissing: coreUtilsLib.urlUtils.addProtocolIfMissing,
    toQueryString: coreUtilsLib.urlUtils.toQueryString,
    toQueryParam: coreUtilsLib.urlUtils.toQueryParam,
    baseUrl: coreUtilsLib.urlUtils.baseUrl,
    setUrlParam: coreUtilsLib.urlUtils.setUrlParam,
    setUrlParams: coreUtilsLib.urlUtils.setUrlParams,
    removeUrlParam: coreUtilsLib.urlUtils.removeUrlParam,
    removeUrlParams: coreUtilsLib.urlUtils.removeUrlParams,
    isExternalUrl,
    parseUrl: coreUtilsLib.urlUtils.parseUrl,
    parseUrlParams: coreUtilsLib.urlUtils.parseUrlParams,
    buildFullUrl: coreUtilsLib.urlUtils.buildFullUrl,
    getParameterByName: coreUtilsLib.urlUtils.getParameterByName,
    isTrue: coreUtilsLib.urlUtils.isTrue,
    joinURL: coreUtilsLib.urlUtils.joinURL,
    isQA,
    noAppSave,
};
