import _ from 'lodash';
import * as dailymotion from './dailymotion';
import * as facebook from './facebook';
import * as file from './file';
import * as twitch from './twitch';
import * as vimeo from './vimeo';
import * as youtube from './youtube';
const VIDEO_PLAYER_TYPES = {
    DAILYMOTION: 'dailymotion',
    FACEBOOK: 'facebook',
    FILE: 'file',
    TWITCH: 'twitch',
    VIMEO: 'vimeo',
    YOUTUBE: 'youtube',
};
const players = {
    [VIDEO_PLAYER_TYPES.DAILYMOTION]: {
        verifier: dailymotion.verifier,
        getEmbeddedUrl: dailymotion.getEmbeddedUrl,
        getUrlFromId: dailymotion.getUrlFromId,
    },
    [VIDEO_PLAYER_TYPES.FACEBOOK]: {
        verifier: facebook.verifier,
        getEmbeddedUrl: facebook.getEmbeddedUrl,
        getUrlFromId: () => null,
    },
    [VIDEO_PLAYER_TYPES.FILE]: {
        verifier: file.verifier,
        getEmbeddedUrl: () => null,
        getUrlFromId: () => null,
    },
    [VIDEO_PLAYER_TYPES.TWITCH]: {
        verifier: twitch.verifier,
        getEmbeddedUrl: twitch.getEmbeddedUrl,
        getUrlFromId: twitch.getUrlFromId,
    },
    [VIDEO_PLAYER_TYPES.VIMEO]: {
        verifier: vimeo.verifier,
        getEmbeddedUrl: vimeo.getEmbeddedUrl,
        getUrlFromId: vimeo.getUrlFromId,
    },
    [VIDEO_PLAYER_TYPES.YOUTUBE]: {
        verifier: youtube.verifier,
        getEmbeddedUrl: youtube.getEmbeddedUrl,
        getUrlFromId: youtube.getUrlFromId,
    },
};
const getTypeFromUrl = (url) => _.findKey(players, (player) => player.verifier(url));
const verifyUrl = (url) => Boolean(getTypeFromUrl(url));
const getEmbeddedUrl = (type, url) => players[type].getEmbeddedUrl(url);
const getUrlFromId = (type, id) => players[type].getUrlFromId(id);
export { getTypeFromUrl, getEmbeddedUrl, getUrlFromId, verifyUrl, VIDEO_PLAYER_TYPES, };
