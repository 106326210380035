import React from 'react';
import _ from 'lodash';
import { ErrorReporter } from '@wix/editor-error-reporter';
const withErrorBoundary = (Component, { subscribeUpdates } = {}) => {
    var _a;
    const componentDisplayName = Component.displayName || Component.name;
    return _a = class ErrorBoundaryComponent extends React.Component {
            constructor() {
                super(...arguments);
                this.state = {
                    hasError: false,
                };
                this._attemptToRecover = _.throttle(() => {
                    var _b, _c;
                    (_c = (_b = this.state).cancelAttemptsToRecover) === null || _c === void 0 ? void 0 : _c.call(_b);
                    this.setState(({ attemptsToRecover = 0 }) => ({
                        attemptsToRecover: attemptsToRecover + 1,
                        cancelAttemptsToRecover: null,
                        hasError: false,
                    }));
                }, 500, {
                    leading: true,
                });
            }
            static getDerivedStateFromError() {
                return { hasError: true };
            }
            componentDidCatch(error, info) {
                // eslint-disable-next-line no-console
                console.error(error, info);
                ErrorReporter.captureException(error, {
                    tags: {
                        throwingComponent: componentDisplayName,
                    },
                    extra: {
                        errorInfo: info,
                        attemptsToRecover: this.state.attemptsToRecover,
                    },
                });
                this.initAttemptsToRecover();
            }
            componentWillUnmount() {
                var _b, _c;
                (_c = (_b = this.state).cancelAttemptsToRecover) === null || _c === void 0 ? void 0 : _c.call(_b);
            }
            initAttemptsToRecover() {
                if (typeof subscribeUpdates !== 'function' ||
                    // NOTE: recover attempts are already initialized
                    typeof this.state.cancelAttemptsToRecover === 'function') {
                    return;
                }
                // TODO: implement recover limit for the case when every recover failed
                // ensureAttemptsToRecoverIsWithinLimit(this.state.attemptsToRecover);
                const unsubscribeUpdates = subscribeUpdates(this._attemptToRecover);
                this.setState({
                    cancelAttemptsToRecover: () => {
                        unsubscribeUpdates();
                        this._attemptToRecover.cancel();
                    },
                });
            }
            render() {
                const { state, props } = this;
                if (state.hasError) {
                    return null;
                }
                return React.createElement(Component, Object.assign(Object.assign({}, props), { 
                    // https://reactjs.org/docs/forwarding-refs.html#forwarding-refs-in-higher-order-components
                    ref: props.ref || props.forwardedRef }));
            }
        },
        _a.displayName = `errorBoundaryComponent(${componentDisplayName})`,
        _a;
};
export default withErrorBoundary;
