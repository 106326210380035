var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from 'react';
const withReloadVariant = (beforeHook, connectDefaultOnChange = false) => (WrappedComp) => {
    const WithReloadVariant = (props) => {
        var _a, _b;
        const isLoaded = useRef(false);
        const [count, updateCount] = useState(0); // used to force update
        function unload() {
            return __awaiter(this, void 0, void 0, function* () {
                if (isLoaded.current) {
                    yield props.unloadVariant(); // unload previous variant
                    isLoaded.current = false;
                }
            });
        }
        function load() {
            return __awaiter(this, void 0, void 0, function* () {
                yield props.createEmptyStyle();
                yield props.loadVariant(props.compType); // load new variant
                isLoaded.current = true;
            });
        }
        const loadVariant = () => __awaiter(void 0, void 0, void 0, function* () {
            if (beforeHook)
                yield beforeHook(props);
            yield unload();
            yield load();
            updateCount(count + 1);
        });
        useEffect(() => {
            loadVariant();
            return function cleanup() {
                unload();
            };
        }, [props.variant, (_a = props.breakpointId) === null || _a === void 0 ? void 0 : _a.id]);
        // Register updateStyleFromSourceCss hook:
        useEffect(() => {
            if (connectDefaultOnChange) {
                props.stylableEditor.stylableDriver.registerBuildHook(props.updateStyleFromSourceCss);
            }
            // cleanup:
            return () => {
                if (connectDefaultOnChange) {
                    props.stylableEditor.stylableDriver.unregisterBuildHook(props.updateStyleFromSourceCss);
                }
            };
        });
        return ((_b = props.stylableEditor) === null || _b === void 0 ? void 0 : _b.getSelection(0)) ? (React.createElement(WrappedComp, Object.assign({}, props, { count: count }))) : null;
    };
    return WithReloadVariant;
};
export default withReloadVariant;
