// @ts-nocheck
import _ from 'lodash';
const URL_REGEX = /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})/;
const verifier = (url) => _.isString(url) && URL_REGEX.test(url);
const getEmbeddedUrl = (url) => {
    const videoId = url.match(URL_REGEX)[1];
    return `//www.youtube.com/embed/${videoId}?wmode=transparent&theme=light&controls=0&autohide=0&loop=1&showinfo=0&autoplay=0`;
};
const getUrlFromId = (videoId) => `http://www.youtube.com/watch?v=${videoId}`;
export { verifier, getEmbeddedUrl, getUrlFromId };
