var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as DOMPurify from 'dompurify';
function makePurifyComponent(sanitize) {
    const getHtml = sanitize
        ? (html) => {
            return React.useMemo(() => {
                return DOMPurify.sanitize(html);
            }, [html]);
        }
        : (html) => html;
    const Component = React.forwardRef((props, ref) => {
        const { tag, html } = props, rest = __rest(props, ["tag", "html"]);
        const __html = getHtml(html);
        return React.createElement(tag, Object.assign(Object.assign({}, rest), { ref, 
            //eslint-disable-next-line no-useless-concat
            ['dangerously' + 'SetInnerHTML']: { __html } }));
    });
    Component.displayName = sanitize
        ? 'SafeInjectHtml'
        : 'ImSureInputIsSafeInjectHtml';
    return Component;
}
// use it only when you 100% sure input is sanitized AND SafeInjectHtml is breaking html
// for 99% of cases, use SafeInjectHtml instead
export const ImSureInputIsSafeInjectHtml = makePurifyComponent(false);
export const SafeInjectHtml = makePurifyComponent(true);
