// @ts-nocheck
import _ from 'lodash';
const URL_REGEX = /facebook\.com\/([^/?].+\/)?video(s|\.php)[/?].*$/;
const verifier = (url) => _.isString(url) && URL_REGEX.test(url);
const getEmbeddedUrl = (url) => {
    const urlRegex = /facebook\.com\/(.+\/videos\/[A-Za-z0-9._%-]*)/gi;
    const videoId = urlRegex.exec(url)[1];
    return `//www.facebook.com/plugins/video.php?href=https://www.facebook.com/${videoId}&width=288&height=162`;
};
export { verifier, getEmbeddedUrl };
