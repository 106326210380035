import _ from 'lodash';
import * as imageKit from '@wix/image-kit';
import url from '../data/url';
const { fittingTypes, alignTypes } = imageKit;
function init() {
    imageKit.populateGlobalFeatureSupport();
}
/**
 * Call Santa imageTransform
 */
function getData(fittingType, src, target, imageFilters) {
    target.width = parseInt(target.width.toString(), 10);
    target.height = parseInt(target.height.toString(), 10);
    return imageKit.getData(fittingType, src, target, imageFilters);
}
/**
 * If the image is larger than the container on all sides - fill, else fit
 */
function getImageScale(imageData, containerWidth, containerHeight) {
    if (imageData.width >= containerWidth &&
        imageData.height >= containerHeight) {
        return imageKit.fittingTypes.SCALE_TO_FILL;
    }
    return imageKit.fittingTypes.SCALE_TO_FIT;
}
/**
 * Get image full path and css from imageServices from an imageData object
 */
function getFromImageData(imageData, containerWidth, containerHeight, fittingType = imageKit.fittingTypes.SCALE_TO_FILL, alignType = imageKit.alignTypes.CENTER, htmlTag = 'img') {
    const imageFilters = imageData.quality || {};
    const devicePixelRatio = imageFilters.devicePixelRatio || window.devicePixelRatio;
    // @ts-expect-error
    const mediaUrlPrefix = window.serviceTopology.staticMediaUrl || '';
    const src = _.assign(_.pick(imageData, ['width', 'height', 'crop']), {
        id: imageData.uri,
    });
    const target = {
        width: containerWidth,
        height: containerHeight,
        htmlTag,
        pixelAspectRatio: devicePixelRatio,
        alignment: alignType,
    };
    const imageComputedProperties = getData(fittingType, src, target, imageFilters);
    if (!url.isExternalUrl(imageComputedProperties.uri)) {
        imageComputedProperties.uri = url.joinURL(mediaUrlPrefix, imageComputedProperties.uri);
    }
    return imageComputedProperties;
}
export { getData, getFromImageData, fittingTypes, alignTypes, getImageScale, init, };
