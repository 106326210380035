const numberSuffixRegex = /\d+$/g;
const statesContainNickname = (editorAPI, states, nickname) => states.some((state) => editorAPI.dsRead.components.code.getNickname(state.pointer) === nickname);
const createNumberSuffixedName = (currentNickname, currentNumberSuffix) => {
    const newNumberSuffix = parseInt(currentNumberSuffix, 10) + 1;
    return currentNickname.replace(numberSuffixRegex, `${newNumberSuffix}`);
};
const recursivelyFindNewValidNickname = (editorAPI, states, currentNickname) => {
    var _a;
    const currentNumberSuffix = (_a = currentNickname.match(numberSuffixRegex)) === null || _a === void 0 ? void 0 : _a[0];
    const newNickname = currentNumberSuffix
        ? createNumberSuffixedName(currentNickname, currentNumberSuffix)
        : `${currentNickname}2`;
    return statesContainNickname(editorAPI, states, newNickname)
        ? recursivelyFindNewValidNickname(editorAPI, states, newNickname)
        : newNickname;
};
export const getDuplicatedStateNickname = (editorAPI, stateRef, states) => {
    const currentNickname = editorAPI.dsRead.components.code.getNickname(stateRef) || null;
    return recursivelyFindNewValidNickname(editorAPI, states, currentNickname);
};
