import React, { Component } from 'react';
import { setDisplayName } from './setDisplayName';
import { wrapDisplayName } from './wrapDisplayName';
export const withContext = (childContextTypes, getChildContext) => (BaseComponent) => {
    class WithContext extends Component {
        constructor() {
            super(...arguments);
            this.getChildContext = () => getChildContext(this.props);
        }
        render() {
            return React.createElement(BaseComponent, Object.assign({}, this.props));
        }
    }
    WithContext.childContextTypes = childContextTypes;
    if (process.env.NODE_ENV !== 'production') {
        return setDisplayName(wrapDisplayName(BaseComponent, 'withContext'))(WithContext);
    }
    return WithContext;
};
