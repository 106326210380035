// @ts-nocheck
import _ from 'lodash';
const VIDEO_URL_REGEX = /(?:www\.|go\.)?twitch\.tv\/videos\/(\d+)($|\?)/;
const CHANNEL_URL_REGEX = /(?:www\.|go\.)?twitch\.tv\/([a-z0-9_]+)($|\?)/;
const verifier = (url) => _.isString(url) && (VIDEO_URL_REGEX.test(url) || CHANNEL_URL_REGEX.test(url));
const getEmbeddedUrl = (url) => {
    const isChannel = CHANNEL_URL_REGEX.test(url);
    const videoId = isChannel
        ? url.match(CHANNEL_URL_REGEX)[1]
        : url.match(VIDEO_URL_REGEX)[1];
    const type = isChannel ? 'channel' : 'video';
    const { hostname } = window.location;
    return `https://player.twitch.tv/?${type}=${videoId}&muted=true&autoplay=false&parent=${hostname}`;
};
const getUrlFromId = (videoId) => `https://www.twitch.tv/videos/${videoId}`;
export { verifier, getEmbeddedUrl, getUrlFromId };
