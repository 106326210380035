var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const imageMimeType = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/gif',
    'image/jpe',
    'image/jfif',
    'image/bmp',
    'image/heic',
    'image/heif',
    'image/tiff',
    'image/tif',
    'image/webp',
    'image/jp2',
    'image/jpg2',
    'image/j2k',
    'image/jpf',
    'image/jpm',
    'image/j2c',
    'image/jpc',
    'image/jpx',
    'image/arw',
    'image/srw',
    'image/nef',
    'image/cr2',
    'image/cr3',
    'image/crw',
    'image/rwl',
    'image/rw2',
    'image/raw',
    'image/raf',
    'image/pef',
    'image/orf',
    'image/mrw',
    'image/dng',
    'image/sr2',
    'image/srf',
    'image/kdc',
    'image/k25',
    'image/dcr',
    'image/x3f',
    'image/erf',
    'image/3fr',
];
export const isImageMimeType = (mimeType) => imageMimeType.includes(mimeType);
function getBaseUrl(windowObj) {
    var _a;
    return `${(_a = windowObj === null || windowObj === void 0 ? void 0 : windowObj.serviceTopology) === null || _a === void 0 ? void 0 : _a.mediaUploadServerUrl}site/media/files/upload/url?`;
}
export const mediaTypesTranslation = {
    'image/jpg': 'picture',
    'image/png': 'picture',
    'image/gif': 'picture',
    'image/jpeg': 'picture',
    'image/jpe': 'picture',
    'image/jfif': 'picture',
    'image/bmp': 'picture',
    'image/heic': 'picture',
    'image/heif': 'picture',
    'image/tiff': 'picture',
    'image/tif': 'picture',
    'image/webp': 'picture',
    'image/jp2': 'picture',
    'image/jpg2': 'picture',
    'image/j2k': 'picture',
    'image/jpf': 'picture',
    'image/jpm': 'picture',
    'image/j2c': 'picture',
    'image/jpc': 'picture',
    'image/jpx': 'picture',
    'image/arw': 'picture',
    'image/srw': 'picture',
    'image/nef': 'picture',
    'image/cr2': 'picture',
    'image/cr3': 'picture',
    'image/crw': 'picture',
    'image/rwl': 'picture',
    'image/rw2': 'picture',
    'image/raw': 'picture',
    'image/raf': 'picture',
    'image/pef': 'picture',
    'image/orf': 'picture',
    'image/mrw': 'picture',
    'image/dng': 'picture',
    'image/sr2': 'picture',
    'image/srf': 'picture',
    'image/kdc': 'picture',
    'image/k25': 'picture',
    'image/dcr': 'picture',
    'image/x3f': 'picture',
    'image/erf': 'picture',
    'image/3fr': 'picture',
    'video/avi': 'video',
};
function uploadSiteFile(mediaAuthToken, fileUploadData, tags) {
    return __awaiter(this, void 0, void 0, function* () {
        const authorizationToken = `APP ${mediaAuthToken}`;
        const paramsObj = {
            media_type: mediaTypesTranslation[fileUploadData.type],
            file_name: fileUploadData.name,
            mime_type: fileUploadData.type,
        };
        const params = Object.entries(paramsObj)
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join('&');
        const url = getBaseUrl(window).concat(params);
        const options = {
            method: 'GET',
            headers: {
                Authorization: authorizationToken,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };
        return fetch(url, options)
            .then((data) => data.json())
            .then(({ upload_token, upload_url }) => uploadFileToMediaPlatform(fileUploadData, upload_url, upload_token, tags));
    });
}
function uploadFileToMediaPlatform(blob, upload_url, upload_token, tags) {
    return __awaiter(this, void 0, void 0, function* () {
        const formData = new FormData();
        formData.append('upload_token', upload_token);
        formData.append('media_type', mediaTypesTranslation[blob.type]);
        formData.append('file', blob);
        if (tags)
            formData.append('tags', tags.toString());
        const options = {
            method: 'POST',
            body: formData,
            processData: false,
            'sec-fetch-site': 'cross-site',
            'sec-fetch-mode': 'cors',
            contentType: false,
        };
        return fetch(upload_url, options)
            .then((data) => data.json())
            .then((data) => data[0]);
    });
}
export default uploadSiteFile;
