import url from '../data/url';
import * as imageTransform from '../imageService/imageTransform';
import { generate as generateGradient, } from '@wix/css-gradient-generator';
const MEDIA_TYPES = {
    VIDEO: 'WixVideo',
};
function getImageDisplayData(fittingType, alignType, imageUri, srcImageWidth, srcImageHeight, containerWidth, containerHeight, quality = 85) {
    const src = {
        id: imageUri,
        width: srcImageWidth,
        height: srcImageHeight,
    };
    const target = {
        width: containerWidth,
        height: containerHeight,
        alignment: alignType,
        htmlTag: 'bg',
        pixelAspectRatio: window.devicePixelRatio,
    };
    return imageTransform.getData(fittingType, src, target, {
        quality,
    });
}
const getColorValueFromData = (data, allowGradients) => {
    var _a;
    if (allowGradients) {
        const fill = ((_a = data.colorLayers) === null || _a === void 0 ? void 0 : _a[0].fill) || data.color;
        return (fill === null || fill === void 0 ? void 0 : fill.type) === 'SolidColor' ? fill.color : fill;
    }
    return data.color;
};
const getBackgroundCSS = (editorAPI, data, allowGradients = true) => {
    const value = getColorValueFromData(data, allowGradients);
    if (typeof value === 'string') {
        return { backgroundColor: editorAPI.theme.colors.get(value) };
    }
    return {
        backgroundImage: generateGradient(value, {
            resolveColor: editorAPI.theme.colors.get,
        }),
    };
};
const getCurrentMedia = (editorAPI, data, allowGradients = true) => {
    var _a, _b;
    //TODO cant measure DOM, data is needed pre render
    const TOP_PANEL_WIDTH = 288;
    const TOP_PANEL_HEIGHT = 184;
    const style = {};
    const backgroundImageParts = [];
    const colorLayer = getBackgroundCSS(editorAPI, data, allowGradients);
    if (data === null || data === void 0 ? void 0 : data.mediaRef) {
        const imageData = ((_a = data.mediaRef) === null || _a === void 0 ? void 0 : _a.type) === MEDIA_TYPES.VIDEO
            ? data.mediaRef.posterImageRef
            : data.mediaRef;
        const previewDisplayData = getImageDisplayData(data.fittingType, data.alignType, imageData.uri, imageData.width, imageData.height, TOP_PANEL_WIDTH, TOP_PANEL_HEIGHT);
        style.opacity = imageData.opacity;
        Object.assign(style, (_b = previewDisplayData.css) === null || _b === void 0 ? void 0 : _b.container);
        backgroundImageParts.push(`url(${url.joinURL(
        // @ts-expect-error
        window.serviceTopology.staticMediaUrl, previewDisplayData.uri)})`);
    }
    if (colorLayer.backgroundColor) {
        style.backgroundColor = colorLayer.backgroundColor;
    }
    else if (colorLayer.backgroundImage) {
        backgroundImageParts.push(colorLayer.backgroundImage);
    }
    if (backgroundImageParts.length) {
        style.backgroundImage = backgroundImageParts.join();
    }
    return style;
};
export { getImageDisplayData, getBackgroundCSS, getCurrentMedia };
