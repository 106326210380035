import { textinputValidators } from './textinputValidators';
/**
 * @deprecated - has false validation for too long case
 * @param pageName
 * @returns {string}
 */
const legacyPageNameValidator = (pageName) => {
    let validationMessage = '';
    const validatorFrom1To40 = textinputValidators.range(1, 40);
    if (!validatorFrom1To40(pageName)) {
        validationMessage = 'Pages_Actions_Error_Page_Name_Too_Short';
    }
    else if (!textinputValidators.notEmptyString(pageName)) {
        validationMessage = 'Pages_Actions_Error_Page_Name_Too_Short';
    }
    else if (!textinputValidators.noHTMLTags(pageName) ||
        !textinputValidators.noEmoji(pageName)) {
        validationMessage = 'Pages_Actions_Rename_Validation';
    }
    return validationMessage;
};
const SUCCESS_VALIDATION = '';
const pageNameValidator = (label) => {
    if (label.trim().length === 0) {
        return 'Pages_Actions_Error_Page_Name_Too_Short';
    }
    if (label.length > 40) {
        return 'Is too long //todo: add label ';
    }
    if (!textinputValidators.noHTMLTags(label) ||
        !textinputValidators.noEmoji(label)) {
        return 'Pages_Actions_Rename_Validation';
    }
    return SUCCESS_VALIDATION;
};
const pagesFolderNameValidator = (label) => {
    if (label.trim().length === 0) {
        return 'PagesPanel_Actions_Folder_Rename_Empty_Error';
    }
    if (!textinputValidators.noHTMLTags(label) ||
        !textinputValidators.noEmoji(label)) {
        return 'PagesPanel_Actions_Folder_Options_Rename_Validation_Characters';
    }
    return SUCCESS_VALIDATION;
};
export { legacyPageNameValidator, pageNameValidator, pagesFolderNameValidator };
