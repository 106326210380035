//check .apply
export function overridable(origFn) {
    //@ts-expect-error
    if (window.isResponsive !== true) {
        return origFn;
    }
    let targetFn = origFn;
    const resultFn = function () {
        //eslint-disable-next-line prefer-rest-params
        return targetFn.apply(this, arguments);
    };
    resultFn.getOrig = () => origFn;
    resultFn.override = (newFunc) => {
        targetFn = newFunc;
    };
    return resultFn;
}
