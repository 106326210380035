// In case we're not running in the browser
const win = typeof window !== 'undefined' ? window : {};
const raf = win.requestAnimationFrame ||
    win.webkitRequestAnimationFrame ||
    win.mozRequestAnimationFrame ||
    win.oRequestAnimationFrame ||
    win.msRequestAnimationFrame ||
    function (/* function */ callback) {
        return window.setTimeout(callback, 1000 / 60);
    };
const caf = win.cancelAnimationFrame ||
    win.webkitCancelAnimationFrame ||
    win.mozCancelAnimationFrame ||
    win.oCancelAnimationFrame ||
    win.msCancelAnimationFrame ||
    window.clearTimeout;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function request(cb) {
    // eslint-disable-next-line prefer-rest-params
    return raf.apply(win, arguments);
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function cancel(cb) {
    // eslint-disable-next-line prefer-rest-params
    return caf.apply(win, arguments);
}
